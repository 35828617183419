import React, { Component, Suspense, useEffect, useState } from 'react'
import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import Loadable from 'react-loadable'
import { useSelector, useDispatch } from 'react-redux'

import '../../node_modules/font-awesome/scss/font-awesome.scss'

import Loader from './layout/Loader'
import Aux from 'src/hoc/_Aux'
import ScrollToTop from './layout/ScrollToTop'
import routes from 'src/route'
import Login from 'src/screens/common/Login'
import ResetPassword from 'src/screens/common/Reset'
import jwt_decode from 'jwt-decode'
import moment from 'moment-timezone'
import { pathuser } from 'src/functions/Main'
import Register from 'src/screens/common/Register'
moment.tz.setDefault('Africa/Nairobi')

const AdminLayout = Loadable({
  loader: () => import('./layout/AdminLayout'),
  loading: Loader,
})

const centerStyle = {
  position: 'fixed',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
}

// class App extends Component {
const App = withRouter(({ history }) => {
  const dispatch = useDispatch()
  const stateFromStore = useSelector((state) => state)
  const { user } = stateFromStore
  const [checking, setChecking] = useState(true)
  const [startSend, setStartSend] = useState(0)
  const [localUserObj, setlocalUserObj] = useState({})
  useEffect(() => {
    const tempToken = localStorage.getItem('sff_token')

    if (tempToken && tempToken !== 'null') {
      //chec if it is valid--by decoding the jwt
      try {
        const decodedToken = jwt_decode(tempToken)
        // return console.log(decodedToken)

        var dateNow = new Date()
        console.log(dateNow.getTime())

        if (decodedToken.exp * 1000 < dateNow.getTime()) {
          // if (tempToken === 0) {
          //has expired
          localStorage.setItem('sff_token', 'null')
          dispatch({ type: 'store_user', payload: null })
          setChecking(false)
        } else {
          const { result } = decodedToken
          const toSend = {
            full_name: result.full_name,
            email: result.email,
            phone: result.phone,
            user_type: result.user_type,
            profile_photo: result.profile_photo,
            user_id: result.user_id,
            token: tempToken,
          }
          console.log(toSend)

          dispatch({ type: 'store_user', payload: toSend })
          const path = 'client'
          // console.log(history.location.pathname, 'is the previous path')
          history.location.pathname === '/login' &&
            history.push(`/dashboard/${path}`)
          // fetchInitialMessages(tempToken, result.user_id)
          setlocalUserObj(toSend)

          setChecking(false)
        }
      } catch (e) {
        console.log(e)
        localStorage.setItem('sff_token', 'null')
        dispatch({ type: 'store_user', payload: null })
        setChecking(false)
      }
    } else {
      // dispatch(logout())
      dispatch({ type: 'store_user', payload: null })
      setChecking(false)
    }
  }, [])

  const menu = routes.map((route, index) => {
    return route.component ? (
      <Route
        key={index}
        path={route.path}
        exact={route.exact}
        name={route.name}
        render={(props) => <route.component {...props} />}
      />
    ) : null
  })

  return (
    <Aux>
      {checking ? (
        <Aux>
          <Loader />
          <div style={centerStyle}>
            <i className="fa fa-spinner fa-spin fa-3x text-info"></i>
          </div>
        </Aux>
      ) : (
        <>
          <ScrollToTop>
            <Suspense fallback={<Loader />}>
              {!user ? (
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/register" component={Register} />
                  <Route path="/reset" exact component={ResetPassword} />
                  <Route exact path="/*">
                    <Redirect to="/login" />
                  </Route>
                </Switch>
              ) : (
                <Switch>
                  {menu}
                  <Route path="/" component={AdminLayout} />
                </Switch>
              )}
            </Suspense>
          </ScrollToTop>
        </>
      )}
    </Aux>
  )
})

export default App
